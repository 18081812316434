import { MQ } from "@/react-app/styles/helpers";
import spaces from "@/react-app/styles/theme/spaces";
import styled from "@emotion/styled/macro";

export const ContainerWrapper = styled.ul`
  display: grid;
  grid-gap: ${spaces.mini};
  grid-template-columns: 1fr;
  max-width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  list-style-type: none;

  ${MQ("tablet")} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: ${spaces.smallRegular};
  }
`;

export const ComponentWrapper = styled.li`
  width: 100%;
`;

import type { GridComponent as GridComponentType } from "@xxl/content-api";
import React from "react";
import { ComponentWrapper, ContainerWrapper } from "./GridContainer.styled";
import GridComponent from "@/react-components/GridComponent/GridComponent";
import type { GridComponentProps } from "@/react-components/GridComponent/GridComponent";

export type GridContainerProps = {
  gridComponents: GridComponentType[];
} & Omit<GridComponentProps, "banners">;

const GridContainer: React.FunctionComponent<GridContainerProps> = ({
  gridComponents,
  priceData,
}) =>
  gridComponents.length > 0 ? (
    <ContainerWrapper>
      {gridComponents.map(
        (item, index) =>
          item.gridBanners !== undefined && (
            <ComponentWrapper key={index}>
              <GridComponent banners={item.gridBanners} priceData={priceData} />
            </ComponentWrapper>
          )
      )}
    </ContainerWrapper>
  ) : null;

export default GridContainer;

import { Apis } from "@/utils/api-helper";
import Keyv from "@keyvhq/core";
import memoize from "@keyvhq/memoize";
import { isNullOrUndefined, type SiteIdentifier } from "@xxl/common-utils";
import type {
  ContentModule,
  DigitalCampaignCategoryHeroBanner,
  DigitalCampaignContentData,
  DigitalCampaignContentResponse,
  DigitalCampaignHeroBanner,
  Level1Category,
} from "@xxl/content-api";
import { filterNull } from "@xxl/type-utils";
import { isDateWithinRange } from "../date-helper";
import { getPreviewableContent } from "../preview-content";
import {
  KeyvCacheNamespace,
  KeyvDefaultTtl,
} from "../server-side-cache/server-side-cache";

export type DigitalCampaignPageContent = {
  campaignId: string;
  campaignHeroBanner: DigitalCampaignHeroBanner;
  categoryBanners: DigitalCampaignCategoryHeroBanner[];
  gridSections: GridSection[];
  landingPageSelectorLabel?: string;
  productCodes: string[];
  productListingSmallBannersIds: string[];
  level1Categories: Level1Category[];
  ipaperLink?: string;
  additionalCampaignIds?: string[];
  abTestsPage?: DigitalCampaignContentData;
};
type GridContainerType = NonNullable<ContentModule["gridContainers"]>[0];
export type GridSection = {
  gridContainers?: GridContainerType[];
  visibleContainersCount?: number | null;
  sectionName: string;
  buttonText?: string;
  buttonUrl: string | null;
  fromDate: string | null;
  toDate: string | null;
};

export const mapGridSectionFromContentModule = (
  contentModule: ContentModule
): GridSection => {
  return {
    gridContainers: contentModule.gridContainers,
    visibleContainersCount: contentModule.visibleContainersCount ?? null,
    sectionName: contentModule.sectionName as string,
    buttonText: contentModule.buttonText,
    buttonUrl: contentModule.categoryPath ?? contentModule.showMoreUrl ?? null,
    fromDate: contentModule.fromDate ?? null,
    toDate: contentModule.toDate ?? null,
  };
};

const getDigitalCampaignPageContentInternal = async (
  siteUid: SiteIdentifier,
  campaignId: string
): Promise<DigitalCampaignContentResponse> => {
  const { data } = await Apis.getInstance().contentApi.getDigitalCampaignPage(
    siteUid,
    campaignId
  );
  return data;
};

const getDigitalCampaignPageContent = memoize(
  getDigitalCampaignPageContentInternal,
  new Keyv({ namespace: KeyvCacheNamespace.DIGITAL_CAMPAIGN_CONTENT }),
  { ...KeyvDefaultTtl, key: (_, campaignId) => campaignId }
);

export const getDigitalCampaignPage = async (
  siteUid: SiteIdentifier,
  campaignId: string,
  documentId?: string,
  previewKey?: string
): Promise<DigitalCampaignPageContent | null> => {
  const {
    data: { result: [digitalCampaignContent] = [] },
  } = await getPreviewableContent({
    getDefaultContentFn: async () => ({
      data: await getDigitalCampaignPageContent(siteUid, campaignId),
    }),
    getPreviewContentFn: () =>
      Apis.getInstance().contentApi.getDigitalCampaignPagePreview(
        siteUid,
        String(documentId),
        String(previewKey)
      ),
    previewCondition: () =>
      previewKey !== undefined && documentId !== undefined,
  });

  if (isNullOrUndefined(digitalCampaignContent)) {
    throw new Error("Campaign doesn't exist");
  }

  if (
    previewKey === undefined &&
    !isDateWithinRange({
      fromDate: digitalCampaignContent.schedule?.fromDate,
      toDate: digitalCampaignContent.schedule?.toDate,
    })
  ) {
    throw new Error("Campaign is inactive");
  }

  const gridSections =
    digitalCampaignContent.landingPage?.contentModules
      ?.filter((item) =>
        isDateWithinRange({
          fromDate: item.fromDate,
          toDate: item.toDate,
        })
      )
      .map((item) => mapGridSectionFromContentModule(item)) ?? [];
  const productCodes =
    digitalCampaignContent.landingPage?.productCodes !== undefined
      ? filterNull(digitalCampaignContent.landingPage.productCodes)
      : [];

  return {
    campaignId: digitalCampaignContent.campaignId,
    campaignHeroBanner: digitalCampaignContent.landingPageBanner,
    categoryBanners: digitalCampaignContent.categoryBanners ?? [],
    productListingSmallBannersIds:
      digitalCampaignContent.productListingSmallBannersIds ?? [],
    gridSections,
    productCodes,
    landingPageSelectorLabel:
      digitalCampaignContent.landingPage?.filterButtonLabel,
    level1Categories: digitalCampaignContent.level1Categories ?? [],
    ipaperLink: digitalCampaignContent.landingPage?.ipaperLink,
    additionalCampaignIds: digitalCampaignContent.additionalCampaignIds,
    abTestsPage: digitalCampaignContent.abTestsPage,
  };
};
